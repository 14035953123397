<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4>
              <i class="ti-stamp"> </i> 单据审批 | 单据记录
            </h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <!-- <el-button type="warning" @click="create(100)" v-if="grid.sea.CAT == 100">新采购单据</el-button> -->
            <!-- <el-button type="primary" @click="create(200)" v-if="grid.sea.CAT == 200">新的领用单</el-button> -->
            <el-button type="warning" @click="create(100)" v-if="grid.sea.STA != 10">新采购单据</el-button>
            <el-button type="primary" @click="create(200)" v-if="grid.sea.STA != 10">新的领用单</el-button>
            <!-- <form id="fm_export" method="post" action="/mat/valid/act/exp" target="_blank" style="display: inline-block; margin: 0px 10px">
              <input type="hidden" name="KEY" v-model="grid.sea.KEY" />
              <input type="hidden" name="CAT" v-model="grid.sea.CAT" />
              <input type="hidden" name="STA" v-model="grid.sea.STA" />
              <input type="hidden" name="DTS" v-model="grid.sea.DTS" />
              <input type="hidden" name="DTE" v-model="grid.sea.DTE" />
              <input type="hidden" name="FOR" v-model="grid.sea.FOR" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <button type="submit" class="el-button" @click="showQrCode">
              <span>二维码</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 2px;">
              <el-select v-model="grid.sea.CAT" @change="filter" clearable placeholder="请选择分类">
                <el-option label="全部" :value=0 />
                <el-option label="采购" :value=100 />
                <el-option label="领用" :value=200 />
              </el-select>
              <el-select v-model="grid.sea.STA" @change="filter" clearable placeholder="请选择状态" style="margin-left: 5px">
                <el-option label="处理中" :value="10" />
                <el-option label="撤消" :value="20" />
                <el-option label="拒绝" :value="30" />
                <el-option label="完结" :value="90" />
              </el-select>
              <el-date-picker v-model="grid.date_range" @change="filter" type="daterange" value-format="yyyy-MM-dd"
                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left: 5px" />
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage">
              <el-table-column prop="CAT_TXT" label="分类" width="60" />
              <el-table-column prop="SN" label="单号" width="120" />
              <el-table-column prop="TITLE" label="标题" width="300" />
              <el-table-column label="总金额(元)" width="100" align="right">
                <template slot-scope="scope">
                  ￥{{ scope.row.RAMT }}
                </template>
              </el-table-column>
              <el-table-column prop="SUB_NAME" label="提交人" width="80" align="center" />
              <el-table-column prop="STEP_DESC" label="步骤" width="200" align="left" />
              <el-table-column prop="STA_TXT" label="状态" width="80" align="center" />
              <el-table-column label="提交日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.CREATED_DT | datetime('YYYY-MM-DD')
                  }}</template>
              </el-table-column>
              <el-table-column label="结束日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.FINISH_DT | datetime('YYYY-MM-DD')
                  }}</template>
              </el-table-column>
              <!-- <el-table-column prop="CLASS_NAME" label="班级" width="100" />
              <el-table-column prop="LOCATION" label="位置" width="100" /> -->
              <el-table-column label="所属" width="100">
                <template slot-scope="scope">{{ scope.row.LOCATION ? scope.row.LOCATION : scope.row.CLASS_NAME
                  }}</template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" icon="el-icon-document" @click="edit(scope.row.ID)">详情</el-button>
                  <!-- <form id="fm_prt" method="post" action="/mat/valid/act/prt" target="_blank" style="display: inline-block; margin: 0px 10px" v-if="scope.row.STA ==90">
                    <input type="hidden" name="ID" v-model="scope.row.ID" /> -->
                  <el-button native-type="submit" type="text" icon="el-icon-printer" v-if="scope.row.STA == 90"
                    @click="print(scope.row.ID)">
                    <span>打印</span>
                  </el-button>
                  <!-- </form> -->
                  <el-button type="text" @click="cancel(scope.row.ID)" v-if="scope.row.STA == 0">撤销</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Create ref="create" @on-saved="filter" />
    </div>
    <div>
      <Edit ref="edit" @on-saved="filter" />
    </div>
    <div>
      <Print ref="print" @on-saved="filter"></Print>
    </div>
    <div>
      <QrCode ref="qrCode" @on-saved="filter"></QrCode>
    </div>
  </div>
</template>
<style></style>
<script>
import Wgrid from "@/components/wgrid";
import Create from "./create"
import Edit from "./edit"
import jsFileDownload from 'js-file-download'
import Print from './print'
import QrCode from './emr2'
export default {
  name: "index",
  components: { Wgrid, Create, Edit, Print, QrCode },
  data() {
    return {
      grid: {
        sea: {
          PAGE_SIZE: 12,
          PAGE_INDEX: 0,
          CAT: parseInt(this.$route.query.tp ?? 0),
          FOR: this.$route.query.for
        },
        ls: [],
        total: 0,
        loading: false
      },
      user: this.whale.user.get(),
    }
  },
  created() {
    if (this.grid.sea.FOR == "VALID")
      this.grid.sea.STA = 10;
    this.filter();
  },
  methods: {
    filter() {
      if (this.grid.date_range != null && this.grid.date_range.length > 1) {
        this.grid.sea.DTS = this.grid.date_range[0];
        this.grid.sea.DTE = this.grid.date_range[1];
      } else
        this.grid.sea.DTS = this.grid.sea.DTE = "";

      // this.grid.sea.PAGE_INDEX = 0;
      this.grid.ls = [];
      this.getList();
    },
    loadPage(pi) {
      this.grid.sea.PAGE_INDEX = pi - 1;
      this.getList();
    },
    getList() {
      let self = this;
      this.grid.loading = true;
      this.whale.remote.getCollection({
        url: "/api/School/MAT/MVActApi/GetList",
        data: this.grid.sea,
        completed: function (its, n) {
          self.grid.ls = its;
          self.grid.total = n;
          self.grid.loading = false;
        }
      })
    },
    create(cat) {
      this.$refs.create.init(cat);
    },
    edit(id) {
      this.$refs.edit.init(id);
    },
    exportExcel() {
      let self = this;
      this.grid.loading = true;
      const formData = new FormData()
      formData.append('KEY', this.grid.sea.KEY || '')
      formData.append('CAT', this.grid.sea.CAT || '')
      formData.append('STA', this.grid.sea.STA || '')
      formData.append('DTS', this.grid.sea.DTS || '')
      formData.append('DTE', this.grid.sea.DTE || '')
      formData.append('FOR', this.grid.sea.FOR || '')
      this.whale.remote.getExport({
        url: "/api/School/MAT/MVAct/Exp",
        data: formData,
        completed: function (its) {
          // console.log('exls', its.Data)
          // self.whale.downLoad(its.Data)
          jsFileDownload(its, '导出单据审批单据记录.xls')
          self.grid.loading = false;
        }
      })
    },
    print(id) {
      // this.$refs.print.init(id)
      let self = this;
      this.grid.loading = true;
      const formData = new FormData()
      formData.append('ID', id || '')
      this.whale.remote.getExport({
        url: "/api/School/MAT/MVAct/Prt",
        data: formData,
        completed: function (its) {
          // console.log('exls', its.Data)
          // self.whale.downLoad(its.Data)
          jsFileDownload(its, '单据.xls')
          self.grid.loading = false;
        }
      })
    },
    showQrCode() {
      this.$refs.qrCode.init();
    },
    cancel(id) {
      let self = this
      this.$confirm("真的要撤消这条记录吗？", "撤消", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        self.grid.loading = true;
        self.whale.remote.getResult({
          url: "/api/School/MAT/MVActApi/Cancel",
          data: { ID: id },
          finally() { self.grid.loading = false; },
          completed: function () {
            self.filter();
          }
        })
      }).catch(() => { })
    }
  }
}
</script>
