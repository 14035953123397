<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增商品</span>
      <div class="row">
        <div class="col-md-12">
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>分类</b></label><br />
                <el-cascader :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN' }" @change="getStock"
                  v-model="cat.val" :options="cat.ls" placeholder="请选择分类" />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>物品</b></label><br />
                <el-select v-model="model.ST_ID" placeholder="请选择物品" @change="stockChange" filterable
                  :filter-method="search">
                  <el-option v-for="item in ls_stock" :key="item.ID" :label="item.NAME" :value="item.ID">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>库存量</b></label>
                <div>
                  <el-input v-model="stock.BALANCE" readonly style="width: 60%;">
                    <template slot="prepend"><i class="el-icon-coin" /></template>
                  </el-input>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>申请数量</b></label>
                <div>
                  <el-input-number v-model="model.CNT" :step="1"></el-input-number>
                </div>
              </div>
            </el-col>
            <el-col :span="8" v-if="act_cat == 100">
              <div class="form-group">
                <label><b>* 金额</b>(元)</label>
                <div>
                  <el-input-number v-model="model.AMT" :step="1" :min="0"></el-input-number>
                </div>
              </div>
            </el-col>
            <el-col :span="8" v-if="act_cat == 200">
              <div class="form-group">
                <label><b>归属班级</b></label><br />
                <el-select v-model="model.CLASS_ID" :disabled="model.LOCATION ? true : false" clearable>
                  <el-option v-for="item in ls_class" :key="item.ID" :label="item.NAME" :value="item.ID">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8" v-if="act_cat == 200">
              <div class="form-group">
                <label><b>位置</b></label><br />
                <!-- <el-select v-model="model.LOCATION" :disabled="model.CLASS_ID ? true : false" clearable>
                  <el-option v-for="item in loc.ls" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select> -->
                <el-cascader v-model="loc.id" :options="loc.ls" placeholder="请选择位置"
                  :disabled="model.CLASS_ID ? true : false" clearable @change="locSel"
                  :props="{ label: 'NAME', value: 'NAME', children: 'CHILDREN' }" />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>备注</b></label>
                <el-input type="text" v-model="model.DESC" />
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-image v-if="stock.PIC_NAME" style="width: 320px;" :src="stock.PIC_URL"
                :preview-src-list="[stock.PIC_URL]" />
            </el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
      act_cat: 100,
      sta: {
        show: false,
        loading: false
      },
      model: {},
      stock: {},
      cat: {
        ls: [],
        val: []
      },
      ls_stock: [],
      ls_class: [],
      loc: {
        ls: [],
        id: ''
      },
    }
  },
  methods: {
    init(cat) {
      this.sta = { show: true, loading: false }
      this.act_cat = cat;
      this.model = {};
      this.stock = {}
      if (this.cat.ls.length == 0) this.getCat();
      if (this.act_cat == 200 && this.ls_class.length == 0) {
        this.getClass()
        this.getDict()
      }
    },
    getClass() {
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/School/ORG/ClassApi/GetList",
        data: {},
        completed: function (its) {
          self.ls_class = its;
        }
      })
    },
    getDict() {
      let self = this;
      // this.whale.remote.getCollection({
      //   url: '/api/School/FX/DictApi/GetList',
      //   data: {
      //     TYPE: 'MAT_VALID_LOC'
      //   },
      //   completed(its) {
      //     self.loc.ls = its[0].VALUE.split(',');
      //   }
      // })
      this.whale.remote.getResult({
        url: "/api/School/FIX/LocationApi/GetTree",
        completed: function (its) {
          self.loc.ls = its.DATA;
        }
      })
    },
    locSel(e) {
      this.model.LOCATION = e.map((option) => option).join('/');
      console.log(this.model.LOCATION)
    },

    getCat() {
      let self = this;
      this.whale.remote.getResult({
        url: "/api/School/MAT/MCatApi/GetTree",
        completed: function (its) {
          self.cat.ls = its.DATA;
        }
      })
    },
    getStock(v) {
      let n = v.length;
      if (n > 0) this.model.CAT_ID = v[n - 1];
      let self = this;
      this.whale.remote.getCollection({
        url: "/api/School/MAT/StockApi/GetList",
        data: { CAT_ID: this.model.CAT_ID },
        completed: function (its, n) {
          self.model.ST_ID = "";
          self.ls_stock = its;
          if (n == 1) self.stockSel(its[0]);
        }
      })
    },
    stockChange(v) {
      let self = this;
      this.ls_stock.forEach(it => {
        if (it.ID == v) {
          self.stockSel(it)
          // self.findCat(it)
          let val = self.catFind(it.CAT_ID, self.cat.ls);
          if (val == undefined) val = it.CAT_ID + '';
          else val += "," + it.CAT_ID;
          this.cat.val = val.split(',').map(o => {
            return parseInt(o)
          })
          console.log(this.cat.val)
        }
      });
    },
    catFind(id, ls) {
      let n = ls.length;
      for (var i = 0; i < n; i++) {
        let it = ls[i]
        if (it.LEAF) {
          if (it.ID != id) continue;
          return it.ANCESTORS;
        } else {
          var ret = this.catFind(id, it.CHILDREN);
          if (ret != undefined) return ret;
        }
      }
    },
    search(key) {
      let self = this
      this.whale.remote.getCollection({
        url: "/api/School/MAT/StockApi/GetList",
        data: { KEY: key },
        completed: function (its) {
          self.ls_stock = its;
        }
      })
    },
    stockSel(it) {
      this.stock = it;
      this.model.ST_ID = it.ID
      this.model.ST_NAME = it.NAME;
      this.model.ST_UNIT = it.FUNIT;
      this.model.ST_PRICE = it.PRICE;
      this.model.CAT_NAME = it.CAT_NAME;
      // this.model.LOCATION = it.LOCATION
      this.model.CAT_ID = it.CAT_ID;
    },
    submit() {
      let self = this;
      if (this.model.ST_ID == undefined) {
        this.whale.toast.err("请选择物品")
        return
      }
      if (this.model.CNT == undefined) {
        this.whale.toast.err("请输入数量")
        return
      }
      if (this.act_cat == 100) {
        if (this.model.AMT == undefined || this.model.AMT <= 0) {
          this.whale.toast.err("请输入金额,且大于0")
          return
        }
      } else {
        this.model.AMT = this.model.ST_PRICE * this.model.CNT;
        if (this.model.CLASS_ID > 0) {
          this.ls_class.map(x => {
            if (x.ID == this.model.CLASS_ID) {
              this.model.CLASS_NAME = x.CLASS_NAME;
              this.model.LOCATION = 0
            }
          })
        } else {
          this.model.CLASS_ID = 0
        }
      }
      self.sta.show = false;
      self.$emit("on-saved", this.model);
    }
  }
}
</script>
