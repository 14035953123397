<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">单据详情</span>
      <div class="row" v-loading="sta.loading">
        <el-card style="width:100%; margin-bottom: 20px">
          <el-divider content-position="center"><span style="color: #3bb667">基础信息</span></el-divider>
          <el-row>
            <el-col :span="6">
              <div class="form-group" style="color: #7e65ff">
                <label><b>当前步骤：</b></label>
                {{ model.STEP_DESC }}
              </div>
            </el-col>
            <el-col :span="6" v-show="false">
              <div class="form-group" style="color: #7e65ff">
                <label><b>单据类型：</b></label>
                {{ model.STEP_IDX_TYPE }}
              </div>
            </el-col>
            <el-col :span="12">
              <div class="form-group">
                <label><b>标题：</b></label>
                {{ model.TITLE }}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-group">
                <label><b>合计金额：</b></label>
                ￥{{ model.RAMT }} 元
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="6">
              <div class="form-group">
                <label><b>单号：</b></label>
                {{ model.SN }}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-group">
                <label><b>分类：</b></label>
                {{ model.CAT_TXT }}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-group">
                <label><b>状态：</b></label>
                {{ model.STA_TXT }}
              </div>
            </el-col>
            <el-col :span="6">
              <div class="form-group">
                <label><b>提交人：</b></label>
                {{ model.SUB_NAME }}
              </div>
            </el-col>
          </el-row>
          <el-table :data="model.gridg" stripe border :cell-style="{ padding: '0px' }" show-summary
            :summary-method="getSummaries"
            :header-cell-style="{ borderBottom: 'solid 1px #ccc', backgroundColor: '#f5f5f5', textAlign: 'center', padding: '5px 0px' }">
            <el-table-column prop="CAT_NAME" label="分类名称" width="150">
              <template slot-scope="scope">
                {{ scope.row.CAT_NAME }} <span v-if="scope.row.STA_TXT" style="color: red;">({{ scope.row.STA_TXT }})</span>
              </template>
            </el-table-column>
            <el-table-column prop="ST_NAME" label="物品名称" width="200" />
            <el-table-column prop="ST_UNIT" label="单位" width="60" align="center" />
            <el-table-column label="数量" width="80" align="center">
              <template slot-scope="scope">
                <el-input type="number" v-model="scope.row.CNT"
                  v-if="model.STEP_IDX == 201 && model.STA == 10 && model.STEP_TEA_ID == model.UID"
                  style="margin: 5px 0px; line-height: 16px" />
                <span v-else>{{ scope.row.CNT }}</span>
              </template>
            </el-table-column>
            <el-table-column label="申请金额(元)" width="120" align="right">
              <template slot-scope="scope">
                ￥{{ scope.row.AMT.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="实际金额(元)" width="120" align="right">
              <template slot-scope="scope">
                <el-input type="number" v-model="scope.row.RAMT"
                  v-if="model.STEP_IDX == 121 && model.STA == 10 && model.STEP_TEA_ID == model.UID"
                  style="margin: 5px 0px; line-height: 16px" />
                <span v-else>￥{{ scope.row.RAMT.toFixed(2) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="DESC" label="备注" width="168" />
          </el-table>

        </el-card>
        <el-card style="width:100%; margin-bottom: 20px">
          <el-divider content-position="center"><span style="color: #3bb667">审核记录</span></el-divider>
          <el-table :data="model.gridv" stripe border :cell-style="{ padding: '0px' }"
            :header-cell-style="{ borderBottom: 'solid 1px #ccc', backgroundColor: '#f5f5f5', textAlign: 'center', padding: '5px 0px' }">
            <el-table-column prop="STEP_NAME" label="步骤" width="80" />
            <el-table-column prop="TEA_NAME" label="姓名" width="80" align="center" />
            <el-table-column prop="DESC" label="意见" width="200" />
            <el-table-column label="时间" width="150">
              <template slot-scope="scope" align="center">{{ scope.row.VTIME | datetime('YYYY-MM-DD HH:mm')
                }}</template>
            </el-table-column>
            <el-table-column />
          </el-table>
        </el-card>
        <el-card style="width:100%; margin-bottom: 20px"
          v-if="model.STA == 10 && model.STEP_TEA_IDS.indexOf(`,${model.UID},`) >= 0">
          <!-- v-if="model.STA == 10 && model.STEP_TEA_IDS.indexOf(`,${model.UID},`) >= 0" -->
          <el-divider content-position="center"><span style="color: #7e65ff">我的意见</span></el-divider>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label><b>拒绝理由：</b></label><br />
                <el-input type="text" v-model="model.DESC" placeholder="请输入您的意见" />
              </div>
              <div>
                <el-button icon="el-icon-video-pause" type="danger" @click="refuse">拒绝</el-button>
                <el-button icon="el-icon-check" type="success" @click="agree">同意</el-button>
              </div>
            </div>
          </div>
        </el-card>
      </div>
      <div slot="footer">
        <!-- <a :href="'/sch/prt.html?id=' + model.ID" target="_blank" style="margin-right: 15px"> -->
        <el-button type="primary" icon="el-icon-printer" @click="print(model.ID)">打印</el-button>
        <!-- </a> -->
        <el-button icon="el-icon-close" @click="sta.show = false">关闭</el-button>
        <el-button icon="el-icon-delete" type="success" @click="cancel"
          v-if="model.STA == 10 && model.UID == model.SUB_ID">撤消</el-button>
      </div>
    </el-dialog>
    <div>
      <Print ref="print"></Print>
    </div>
  </div>
</template>
<script>
import Print from './print'
export default {
  components: { Print },
  data() {
    return {
      sta: {
        show: false,
        loading: false
      },
      model: {}
    }
  },
  methods: {
    init(id) {
      this.sta = { show: true, loading: true }
      this.model = {};
      let self = this;
      this.whale.remote.getResult({
        url: "/api/School/MAT/MVActApi/Get",
        data: { ID: id },
        completed: function (m) {
          self.sta.loading = false
         
          let newG = JSON.parse(m.DATA.GBODY);
          newG.forEach(j => {
            m.DATA.StockNotEnoughStockIds.forEach(i => {
              if (j.ST_ID == i) {
                j.STA_TXT = '库存不足'
              }
            })
          })

          m.DATA.gridg = newG
          m.DATA.gridg.map((o) => {
            if (!o.RAMT) o.RAMT = o.AMT;
          })
          m.DATA.gridv = JSON.parse(m.DATA.VBODY);
          self.model = m.DATA;
          let user = self.whale.user.get();
          self.model.UID = user.ID;
        }
      })
    },
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [];
      columns.forEach((col, i) => {
        if (i == 0) {
          sums[i] = "合计："
          return
        }
        if (data.length > 0) {
          let C3 = data.map(o => Number(o.AMT));
          let C4 = data.map(o => Number(o.RAMT));
          if (i == 4) {
            let val = C3.reduce((pre, cur) => {
              return pre + cur;
            })
            sums[i] = val.toFixed(2)
          } else if (i == 5) {
            let val = C4.reduce((pre, cur) => {
              return pre + cur;
            })
            sums[i] = val.toFixed(2)
          }
        }
      })
      return sums
    },
    cancel() {
      let self = this;
      this.$confirm("真的要撤消这条记录吗？", "撤消", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        self.whale.remote.getResult({
          url: "/api/School/MAT/MVActApi/Cancel",
          data: { ID: self.model.ID },
          finally() { self.sta.loading = false; },
          completed: function () {
            self.sta.show = false;
            self.$emit("on-saved");
          }
        })
      }).catch(() => { })
    },
    refuse() {
      let self = this;
      self.sta.loading = true;
      this.whale.remote.getResult({
        url: "/api/School/MAT/MVActApi/Refuse",
        data: {
          ID: self.model.ID,
          DESC: self.model.DESC
        },
        finally() { self.sta.loading = false; },
        completed: function () {
          self.sta.show = false;
          self.$emit("on-saved");
        }
      })
    },
    agree() {
      let self = this;
      let url = "/api/School/MAT/MVActApi/Agree";
      let idx = self.model.STEP_IDX;
      let idx_type=self.model.STEP_IDX_TYPE;
      if (idx == 121) {
        url += "121";
      } else if (idx == 122) {
        url += "122";
      } else if (idx == 201) {
        url += "201";
      } else {
        if (idx_type == 1||idx_type==2) {
          //采购审批流程
          url += "110";
        } 
      }
     

      //采购人，调整实际金额
      if (idx == 121 || idx == 201) {
        let ramt = 0;
        self.model.gridg.map(o => {
          o.RAMT = Number(o.RAMT);
          ramt += o.RAMT
        });
        self.model.GBODY = JSON.stringify(self.model.gridg);
        self.model.RAMT = ramt;
      }

      self.sta.loading = true;
      this.whale.remote.getResult({
        url: url,
        data: self.model,
        finally() { self.sta.loading = false; },
        completed: function () {
          self.sta.show = false;
          self.$emit("on-saved");
        }
      })
    },
    print(id) {
      this.$refs.print.init(id)
    }
  }
}
</script>
